<template>
  <v-form class="pa-4">
    <v-container fluid="">
      <v-row>
        <v-col cols="12" sm="6">
          <h3>Update Password</h3>
          <p>
            Update your password or recover your current one.
            <router-link to="/forgot-password">Forgot password ?</router-link>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="5">
          <v-text-field
            :append-icon="show3 ? 'visibility_off' : 'visibility'"
            :rules="[rules.required, rules.min]"
            :type="show3 ? 'text' : 'password'"
             data-test="input_password"
            name="input-10-2"
            label="Password"
            hint="At least 8 characters"
            v-model="password"
            class="input-group--focused"
            @click:append="show3 = !show3"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="5">
          <v-text-field
            :append-icon="show4 ? 'visibility_off' : 'visibility'"
            :rules="[rules.required, rules.emailMatch]"
            :type="show4 ? 'text' : 'password'"
            data-test="input_verifypassword"
            name="input-10-2"
            v-model="verifypassword"
            label="Verify Password"
            hint="At least 8 characters"
            @click:append="show4 = !show4"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="5">
          <v-btn
            id="reset_btn"
            rounded
            color="info"
            type="submit"
            :disabled="!(verifypassword && verifypassword === this.password)"
            @click.prevent="changePassword"
          >
            Update Password
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
export default {
  data() {
    return {
      show3: false,
      show4: false,
      verifypassword: null,
      password: null,
      rules: {
        required: value => !!value || 'Required.',
        min: v => (v && v.length >= 8) || 'Min 8 characters',
        emailMatch: () =>
          this.verifypassword && this.verifypassword !== this.password
            ? "The email and password you entered don't match"
            : true
      }
    }
  },
  methods: {
    changePassword() {
      console.log('Change User Password')
      this.$store.dispatch('changePassword', this.password).then(() => {
        this.password = null
        this.verifypassword = null
      })
    }
  }
}
</script>
